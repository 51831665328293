import trans_cs from '../translations/compiled/cs.json';
// import trans_sk from '../translations/compiled/sk.json';

const translations = {
    cs: trans_cs
    // sk: trans_sk,
};

export function getCurrentTranslation(locale) {
    return translations[locale];
};