var config = null;

export function init(newConfig = {}) {
    config = newConfig;
}

export function get(key) {

    if (!config) {
        return null;
    }

    return config[key] ?? null;
}

export default {
    init, get
}
