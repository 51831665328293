import React from 'react';
import ReactDOM from 'react-dom';
import Config from './utils/config';

const searchElement = document.getElementById('search-pub-num-highlight');
const autocompleteElement = document.getElementById('search-autocomplete');
const mobileSearchElement = document.getElementById('search-pub-num-highlight-mobile');
const filterAutocompleteElement = document.getElementById('search-autocomplete-filter');

Config.init(Object.assign(
    {},
    searchElement?.dataset ?? {},
    autocompleteElement?.dataset ?? {},
    mobileSearchElement?.dataset ?? {},
    filterAutocompleteElement?.dataset ?? {},
));

import SearchAutocomplete from './SearchAutocomplete';
import SearchPubNumHighlight from "./SearchPubNumHighlight";

if (searchElement) {
    ReactDOM.render(<SearchPubNumHighlight />, searchElement);
}

if (autocompleteElement) {
    ReactDOM.render(<SearchAutocomplete />, autocompleteElement);
}

if (mobileSearchElement) {
    ReactDOM.render(<SearchPubNumHighlight isMobile={true} />, mobileSearchElement);
}

if (filterAutocompleteElement) {
    ReactDOM.render(<SearchAutocomplete filter={true} />, filterAutocompleteElement);
}
