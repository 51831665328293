import React from 'react';
import PropTypes from "prop-types";
import {IntlProvider} from 'react-intl';

import {SearchForm} from "../SearchAutocomplete/SearchForm";
import code from "../utils/code";
import Config from "../utils/config";

import {getCurrentTranslation} from '../utils/translations.js';
import classnames from "classnames";

export default class SearchPubNumHighlight extends React.Component {
    static propTypes = {
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentWillMount() {
        const url = new URL(window.location);
        const query = url.searchParams.get("query") || "";

        this.setState({
            query
        });
    }

    onInputChange(v) {
        if (!code.isValidCodeChange(v, this.state.query)) {
            return;
        }

        this.setState({query: v});
    }

    render() {
        const {query} = this.state;
        const {isMobile} = this.props;

        return (
            <IntlProvider messages={getCurrentTranslation(Config.get('locale'))} locale={Config.get('locale')}
                          defaultLocale='cs'>
                <div className={classnames("search-inline search-inline--lg")}>
                    <SearchForm onInputChange={this.onInputChange} value={query} isMobile={isMobile}
                                isHomepage={false}/>
                </div>
            </IntlProvider>
        );
    }
}
